import * as profile from "./profile";
import * as workspace from "./workspace";
import * as ai from "./ai";
import * as user from "./user";

export const Tracking = {
  profile,
  workspace,
  ai,
  user,
} as const;
