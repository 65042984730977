import posthog from "posthog-js";

export const trackRunPrompt = () => {
  posthog.capture("ai_run_prompt");
};

export const trackReRunPrompt = () => {
  posthog.capture("ai_rerun_prompt");
};

export const trackRejectPrompt = () => {
  posthog.capture("ai_reject_prompt");
};

export const trackClosePrompt = () => {
  posthog.capture("ai_close_prompt");
};

export const trackAcceptPrompt = () => {
  posthog.capture("ai_accept_prompt");
};
