import type { Method } from "@/model";
import posthog from "posthog-js";

export const trackNewExperimentScreen = () => {
  posthog.capture("new_experiment_screen");
};

export const trackNewExperimentFromTemplate = (templateName: string) => {
  posthog.capture("new_experiment_from_template", { templateName });
};

export const trackExperimentScreen = (experimentId: string) => {
  posthog.capture("experiment_screen", { experimentId });
};

export const trackNewMethodScreen = () => {
  posthog.capture("new_method_screen");
};

export const trackMethodScreen = (methodId: string) => {
  posthog.capture("method_screen", { methodId });
};

export const trackNewMethod = (method: Method, fromImport?: boolean) => {
  posthog.capture("new_method", { ...method, fromImport });
};

export const trackWorkspaceScreen = (workspaceId: string) => {
  posthog.capture("workspace_screen", { workspaceId });
};

export const trackNewWorkspaceScreen = () => {
  posthog.capture("new_workspace_screen");
};

export const trackNewWorkspace = (workspaceName: string) => {
  posthog.capture("new_workspace", { workspaceName });
};
