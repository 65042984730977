import posthog from "posthog-js";

export const trackProfileView = (username: string) => {
  posthog.capture("profile_viewed", {
    username,
    path: window.location.pathname,
  });
};

export const trackProfileCreate = (username: string) => {
  posthog.capture("profile_created", {
    username,
  });
};

export const trackProfileEdit = (username: string) => {
  posthog.capture("profile_edited", {
    username,
  });
};

export const trackProfileDelete = (username: string) => {
  posthog.capture("profile_deleted", {
    username,
  });
};

export const trackPortfolioItemAdd = (
  username: string,
  itemType: string,
  itemId: string
) => {
  posthog.capture("portfolio_item_added", {
    username,
    item_type: itemType,
    item_id: itemId,
  });
};

export const trackPortfolioItemRemove = (
  username: string,
  itemId: string,
  collectionId: string
) => {
  posthog.capture("portfolio_item_removed", {
    username,
    item_id: itemId,
    collection_id: collectionId,
  });
};

export const trackPortfolioCollectionCreate = (
  username: string,
  collectionId: string,
  title: string
) => {
  posthog.capture("portfolio_collection_created", {
    username,
    collection_id: collectionId,
    collection_title: title,
  });
};

export const trackPortfolioCollectionUpdate = (
  username: string,
  collectionId: string,
  title: string
) => {
  posthog.capture("portfolio_collection_updated", {
    username,
    collection_id: collectionId,
    collection_title: title,
  });
};

export const trackPortfolioCollectionDelete = (
  username: string,
  collectionId: string
) => {
  posthog.capture("portfolio_collection_deleted", {
    username,
    collection_id: collectionId,
  });
};
